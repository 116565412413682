import { validator } from '@bcase/core';
import { defineCustomElements } from '@bcase/core/dist/custom-elements';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/pro-solid-svg-icons';
import React from 'react';
import ReactDOM from 'react-dom';

async function main() {
  library.add(fas);
  defineCustomElements(window);

  window.BCE.setColorScheme('light');
  validator.translator = async ctx => {
    if (ctx.rule === 'required') return 'Dit veld is verplicht';
    return ctx.message;
  };

  const el = document.getElementById('root');
  const { App } = await import('./layout/App');
  ReactDOM.render(<App />, el);
}

main();
